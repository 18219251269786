// Libraries.
import React, { useState } from "react"
import { Link } from "gatsby"

// Shared Components.
import { GlobalStyle } from "@shared/GlobalStyles"

// Components.
import Hero from "@sections/Hero/Hero"
import Name from "@sections/Name/Name"
import Goals from "@sections/Goals/Goals"
import Briefcase from "@sections/Briefcase/Briefcase"
import Clients from "@sections/Clients/Clients"
import Contact from "@sections/Contact/Contact"
import Footer from "@sections/Footer/Footer"
import Modal from "@sections/Modal/Modal"
import SideBar from "@sections/Hero/Sidebar/Sidebar"
import ListenEsc from "@hooks/listenEsc"

const IndexPage = () => {
  const [modalData, setData] = useState({})
  const closeModal = () => setData({})

  return (
    <>
      <GlobalStyle removeOverflow={modalData.sidebar || modalData.modal} />
      <ListenEsc closeModal={closeModal} />
      <SideBar isOpen={modalData.sidebar} closeModal={closeModal} />
      <Modal modalData={modalData} setData={setData} closeModal={closeModal} />
      <Hero setData={setData} />
      <Name />
      <Goals />
      <Briefcase setData={setData} />
      <Clients />
      <Contact />
      <Footer />
    </>
  )
}

export default IndexPage
